import React, { useState, useEffect } from "react";
import { PieChart, Pie, Sector, ResponsiveContainer } from "recharts";
import { useMediaQuery, useTheme } from "@material-ui/core";

const renderActiveShape = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    value
  } = props;

  const criteria = {
    0: 'Loading...',
    1: 'Poor',
    2: 'Fair',
    3: 'Great',
    4: 'Fantastic',
    5: 'Fantastic+'
  };



  return (
    <g>
      <text x={cx} y={cy} dy={30} dx={-10} textAnchor="start" fill={fill} style={{ fontSize: 18, letterSpacing: 0.18 }} >
        { value === "No Data" ? "No Data" : criteria[String(value / 2).split("")[0]] }
      </text>
      <text x={cx} y={cy} dy={30} dx={-25} textAnchor="end" fill={'#242424'} style={{ fontSize: 18, letterSpacing: 0.18 }} >
        {`${payload.name}:`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

export default function ScoreGraph(props) {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
  const [data, setData] = useState([
    { name: "Group A", value: 0 },
    { name: "Group B", value: 0, fill: 'white' }
  ])

  useEffect(()=>{
    setData([
      { name: props.title, value: (props.grading === "No Data" ? "No Data" : props.grading), fill: props.color },
      { name: "", value: 100 - (props.grading === "No Data" ? 0 : props.grading), fill: 'white' }
    ])

  },[props.grading])

  return (
    <ResponsiveContainer width={matches ? "100%" : 300} height={200}>
      <PieChart >
        <Pie
          activeIndex={0}
          activeShape={renderActiveShape}
          data={data}
          startAngle={180}
          cx={"55%"}
          cy={100}
          endAngle={0}
          innerRadius={'80%'}
          outerRadius={'90%'}
          fill="#0095FF"
          dataKey="value"
          isAnimationActive={true}
        />
      </PieChart>
    </ResponsiveContainer>
  );
}