import useStyles from './styles';
import { CircularProgress, Paper, useMediaQuery, useTheme, Typography, Button, Card, CardContent, FormControl, Select, InputLabel, MenuItem, Backdrop, Grid } from '@material-ui/core';
import OverallScore from '../Dashboard/OverallScore';
import { fetchWithAuth } from '../utils/utils';
import { useState, useEffect, useContext } from "react";
import ReliabilityCard from '../Dashboard/ReliabilityCard';
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';
import StatListCard from '../Dashboard/StatListCard';
import '../fonts/styles.css';
import HeaderContext from '../utils/HeaderContext';

const DriverDashboard = (props) => {
	const classes = useStyles();
	const small = useMediaQuery(useTheme().breakpoints.down('sm'));
	const medium = useMediaQuery(useTheme().breakpoints.down('md'));
	const { header, setHeader } = useContext(HeaderContext);

	const [driver, setDriver] = useState(''); //Form Select of Driver
	const [circle, setCircle] = useState(0); // Circle prop passed down to Overall Graph. this is set by the fetch that grabs driver stats

	const [allDates, setAllDates] = useState(); // Initial render, grab all years and weeks available

	const [weeks, setWeeks] = useState([]); //All weeks available from pdfs
	const [years, setYears] = useState([]); //All years available from pdfs
	const [drivers, setDrivers] = useState([]); // All drivers for the selected year and week

	const [weeklyStats, setWeeklyStats] = useState([]); //Stats for the Statlist tables
	const [attributes, setAttributes] = useState({ }); //Labels for the Statlist tables
	const [attributeLabels, setAttributeLabels] = useState([]); //Labels used for the LineChart at the bottom *TODO

	const [chartAttribute, setChartAttribute] = useState(''); //Attributed selected to display on the LineChart
	const [chartData, setChartData] = useState([]); // Holds week time value 
	const [ totalChartData, setTotalChartData ] = useState([]);

	const [safetyScore, setSafetyScore] = useState(""); 
	const [qualityScore, setQualityScore] = useState("");
	const [overallScore, setOverallScore] = useState("");
	const [coachingMsg, setCoachingMsg] = useState("");
	const [keyFocusArea, setKeyFocusArea] = useState("");

	const [formYear, setFormYear] = useState(''); //Select Menu Year selected
	const [formWeek, setFormWeek] = useState(''); //Select Menu Week selected
	const [hide, setHide] = useState(true); //Hides cards until onSubmit

	const [startDate, setStart] = useState(''); //SELECTED LineChart Start Date Range
	const [startData, setStartData] = useState([]); // Array of Start Dates to choose from in LineChart
	const [endData, setEndData] = useState([]); // Array of End Dates to choose from in LineChart
	const [endDate, setEnd] = useState(''); // SELECTED LineChart End Date 
	const [chart, setChart] = useState([]);

	const [loading, setLoading] = useState(false)

	const getDate = (w, y) => {
		var d = (1 + (w - 1) * 7); // 1st of January + 7 days for each week
		return new Date(y, 0, d).getTime();
	}

	function sleep(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
			},
		},
	};

	const getChartAttributes = (raw) => Object.entries(raw).filter(item => item[1] !== "")

	const formatDateTicks = (tick) => {
		const dataPoint = chartData.find(item => item.time === tick)
		if (!dataPoint) {
			return ''
		}
		const [formYear, formWeek] = dataPoint.label?.split("-W")
		return `Week ${parseInt(formWeek)} ${formYear}`
	}

	// fetch dates on page load
	useEffect(() => {
		setHeader("Coaching");
		setLoading(true)
		fetchWithAuth(`${props.api}/api/weeks`)
			.then(resp => {
				if (resp.status === 401) {
					window.location.pathname = '/';
					return;
				}
				return resp.json();
			})
			.then(data => {
				const dates = data["dates"];
				setAllDates(data);

				const years = Object.keys(dates)
				const weeks = Object.values(dates)
				const totalWeeks = [...new Set([].concat.apply([], weeks))];

				setYears(years.sort().reverse())
				setWeeks(totalWeeks.sort().reverse());
				setLoading(false)
			})
			.catch((e) => {
				console.error(e)
			})

	}, [])

	// get the drivers for the selected week
	useEffect(() => {
		if (formYear && formYear !== " ") {
			setWeeks(allDates['dates'][formYear].sort().reverse());
			if (!weeks.includes(formWeek)) {
				setFormWeek('');
			}
		} else if (formYear && formYear === " ") {
			const weeks = Object.values(allDates["dates"])
			setWeeks([...new Set([].concat.apply([], weeks))].sort().reverse());
		}

		if (formWeek && formYear && formYear !== " " && formWeek !== " ") {
			const date = `${formYear}-W${formWeek}`
			fetchWithAuth(`${props.api}/api/drivers?week=${encodeURIComponent(date)}`)
				.then(resp => {
					if (resp.status === 401) {
						window.location.pathname = '/';
						return;
					}
					return resp.json();
				})
				.then(data => {
					setDrivers(data)
				})
				.catch((e) => {
					console.error(e);
				})
		}

	}, [formWeek, formYear])

	const onChangeStartDate = (value) => {
		const a = totalChartData.filter(x => x.time >= getDate(value.split("W")[1], value.split("-")[0])).map(x => x.label);
		setStart(value);
		setEndData(a);
	}

	const onChangeEndDate = (value) => {
		const a = totalChartData.filter(x => x.time <= getDate(value.split("W")[1], value.split("-")[0])).map(x => x.label);
		setEnd(value);
		setStartData(a);
	}

	// get the stats for the selected driver
	const getStats = () => {
		const date = `${formYear}-W${formWeek}`;
		fetchWithAuth(`${props.api}/api/driverstats/weekly?week=${encodeURIComponent(date)}&driver=${encodeURIComponent(driver)}`)
			.then(resp => {
				if (resp.status === 401) {
					window.location.pathname = '/';
					return;
				}
				return resp.json();
			})
			.then(data => {
				setWeeklyStats(data[0])
			})
			.catch((e) => {
				console.error(e)
			})

		fetchWithAuth(`${props.api}/api/driverstats/score?week=${encodeURIComponent(date)}&driver=${encodeURIComponent(driver)}`)
			.then(resp => {
				if (resp.status === 401) {
					window.location.pathname = '/';
					return;
				}
				return resp.json();
			})
			.then(data => {
				console.log(data)
				setSafetyScore(data.safety_score)
				setQualityScore(data.quality_score)
				setOverallScore(data.overall_score)
				setCircle(data.overall_score)
			})
			.catch((e) => {
				console.error(e)
			})

		fetchWithAuth(`${props.api}/api/driverstats/coach?week=${encodeURIComponent(date)}&driver=${encodeURIComponent(driver)}`)
			.then(resp => {
				if (resp.status === 401) {
					window.location.pathname = '/';
					return;
				}
				return resp.json();
			})
			.then(data => {
				const kfa = {
					"SSVR" : "Sign Signals Violations Rate",
					"DCR" : "Delivery Completion Rate",
					"FICO": "FICO",
					"SER" : "Speeding Event Rate",
					"DAR" : "Delivered and Received",
					"ADA" : "Attended Delivery Accuracy",
					"CC" : "Contact Compliance",
					"DR" : "Distractions Rate",
					"POD" : "Photo on Delivery",
					"SC" : "Scan Compliance", 
					"FDR" : "Following Distance Rate"
				};
				setKeyFocusArea(kfa[data.keyfocusarea] ? kfa[data.keyfocusarea] : data.keyfocusarea);
				setCoachingMsg(data.message)
			})
			.catch(e => {
				console.error(e)
			})
	}

	const getDriverStats = () => {
		fetchWithAuth(`${props.api}/api/driverstats`)
			.then(resp => {
				if (resp.status === 401) {
					window.location.pathname = '/';
					return;
				}
				return resp.json();
			})
			.then(data => {
				const attribs = getChartAttributes(data)
				setAttributes(data)
				setAttributeLabels(attribs)
			})
			.catch((e) => {
				console.error(e)
			})
	}

	const getChartAttri = (start, end) => {
		const chartUrl = new URL(`${props.api}/api/driverstats/historical`);
		chartUrl.searchParams.append("driver", driver);
		chartUrl.searchParams.append('attribute', chartAttribute);
		if (start && end && start !== '' && end !== '') {
			chartUrl.searchParams.append("start", startDate);
			chartUrl.searchParams.append("end", endDate);
		}
		fetchWithAuth(chartUrl.href)
			.then(resp => {
				if (resp.status === 401) {
					window.location.pathname = '/';
					return;
				}
				return resp.json();
			})
			.then(data => {
				const transformed = data.reduce((acc, item) => {
					const [year, week] = item.week.split('-W')
					const itemDate = getDate(parseInt(week), parseInt(year))
					return [
						...acc,
						{
							label: item.week,
							time: itemDate,
							value: parseFloat(item?.[chartAttribute])
						}
					]
				}, [])
				setChartData(transformed);
				setChart(transformed);
			})
			.catch((e) => {
				console.error(e)
			})
	}

	const getAllChartAttri = () => {
		const chartUrl = new URL(`${props.api}/api/driverstats/historical`);
		chartUrl.searchParams.append("driver", driver);
		chartUrl.searchParams.append('attribute', chartAttribute);
		fetchWithAuth(chartUrl.href)
			.then(resp => {
				if (resp.status === 401) {
					window.location.pathname = '/';
					return;
				}
				return resp.json();
			})
			.then(data => {
				const transformed = data.reduce((acc, item) => {
					const [year, week] = item.week.split('-W')
					const itemDate = getDate(parseInt(week), parseInt(year))
					return [
						...acc,
						{
							label: item.week,
							time: itemDate,
							value: parseFloat(item?.[chartAttribute])
						}
					]
				}, [])
				console.log(transformed)
				setTotalChartData(transformed);
				setStartData(transformed.map(x => x.label))
				setEndData(transformed.map(x => x.label))
			})
			.catch((e) => {
				console.error(e)
			})
	}

	const handleGraphSubmit = (e) => {
		e.preventDefault();
		getChartAttri(startDate, endDate);
	}

	useEffect(() => {
		if(chartAttribute === ''){
			return;
		}
		setStartData([]);
		setEndData([]);
		setStart('');
		setEnd('');
		setChart([]);
		getAllChartAttri();
	}, [chartAttribute])

	// useEffect(() => {
	// 	if(driver === ''){
	// 		return;
	// 	}
	// 	getStats();
	// 	getDriverStats();
	// }, [driver])

	return (
		<div style={{ width: "100%", overflow: 'hidden' }}>
			<Backdrop style={{ zIndex: useTheme().zIndex.drawer + 1, color: '#fff', }} open={loading}>
				{loading ? <CircularProgress color="inherit" /> : null}
			</Backdrop>
			{small ? <Typography className={classes.pageHeader}>{header}</Typography> : null}
			<form onSubmit={(e) => {
				e.preventDefault();
				getStats();
				getDriverStats();
				setChart([]);
				setEnd();
				setStart();
				setChartAttribute('');
				setHide(false);
				setHeader(`Coaching - ${driver}`);
			}}>
				<Grid container style={{ marginTop: (small ? null : '2em') }} spacing={2}>
					<Grid item xs={6} md={2}>
						<FormControl className={classes.dashboardSelect} >
							<InputLabel className={classes.selectLabels}>Year</InputLabel>
							<Select
								required
								value={formYear}
								onChange={(e) => { setFormYear(e.target.value) }}
								className={classes.selectedValue}
							>
								<MenuItem value=" "></MenuItem>
								{years.map((year, i) => {
									return (<MenuItem value={year} className={classes.menuItems} key={`yearselect-${i}`}>{year}</MenuItem>)
								})}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={6} md={2}>
						<FormControl className={classes.dashboardSelect}>
							<InputLabel className={classes.selectLabels}>Week</InputLabel>
							<Select
								required
								value={formWeek}
								onChange={(e) => {
									setFormWeek(e.target.value)
								}}
								className={classes.selectedValue}
								MenuProps={MenuProps}
							>
								<MenuItem value=" "></MenuItem>
								{weeks.map((week, i) => {
									return (<MenuItem value={week} className={classes.menuItems} key={`weekselect-${i}`}>{week}</MenuItem>)
								})}

							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} md={2}>
						<FormControl className={classes.dashboardSelect}>
							<InputLabel className={classes.selectLabels} >Driver</InputLabel>
							<Select
								required
								value={driver}
								onChange={(e) => {
									setDriver(e.target.value);
								}}
								className={classes.selectedValue}
								MenuProps={MenuProps}
							>
								{drivers.map((driver, i) => {
									return (<MenuItem value={driver} className={classes.menuItems} key={`driverselect-${i}`}>{driver}</MenuItem>)
								})}
							</Select>
						</FormControl>
					</Grid>
					<Grid item md={2}>
						{small ? null :
							<Button variant="contained" type="submit" fullWidth style={{ marginLeft: 10, marginTop: 10, backgroundColor: '#797198', color: 'white', height: 40, fontSize: 14, letterSpacing: 0.14, fontFamily: 'proxima_novabold', }}>Submit</Button>
						}
					</Grid>
				</Grid>
				{small ? <Button variant="contained" type="submit" fullWidth style={{ marginTop: 24, backgroundColor: '#797198', color: 'white', height: 50, fontSize: 14, letterSpacing: 0.14, fontFamily: 'proxima_novabold', }}>Submit</Button> : null}
			</form>
			{hide ? null : <Grid container spacing={4} style={{ marginTop: "1em" }}>
				<Grid item xs={12} md={4} style={{ paddingLeft: (small ? 0 : null), paddingRight: (small ? 0 : null) }}>
					<OverallScore score={overallScore} safety={safetyScore} quality={qualityScore} circle={circle} />
				</Grid>
				<Grid item xs={12} md={4} style={{ paddingLeft: (small ? 0 : null), paddingRight: (small ? 0 : null) }} >
					<StatListCard
						headerText="Safety:"
						tableHeaders={["Metric", "This Week"]}
						showRows={["ficoscore", "seatbelt-offrate", "speedingeventrate", "distractionsrate", "signsignalviolationsrate", "followingdistancerate"]}
						data={weeklyStats}
						labelMap={attributes}
						score={safetyScore}
					/>
				</Grid>
				<Grid item xs={12} md={4} style={{ paddingLeft: (small ? 0 : null), paddingRight: (small ? 0 : null) }}>
					<StatListCard
						headerText="Quality:"
						tableHeaders={["Metric", "This Week"]}
						showRows={["dar", "swc-pod", "swc-cc", "swc-sc", "swc-ad", "dnrs"]}
						data={weeklyStats}
						labelMap={attributes}
						score={qualityScore}
					/>
				</Grid>
				<Grid item xs={12} md={6} style={{ paddingLeft: (small ? 0 : null), paddingRight: (small ? 0 : null) }}>
					<ReliabilityCard headerText="Reliability" />
				</Grid>
				<Grid item xs={12} md={6} style={{ paddingLeft: (small ? 0 : null), paddingRight: (small ? 0 : null) }}>
					<Card style={{ height: '100%' }}>
						<CardContent>
							<Typography variant='h6' style={{ fontSize: 24, letterSpacing: 0.24, fontFamily: 'proxima_novabold', color: '#242424', paddingBottom: 10 }} >Key Area Focus</Typography>
							<div style={{
								textAlign: 'left',
								marginLeft: '1.5em',
								marginRight: '0.75em',
							}}>
								<span className={classes.focusArea}> { keyFocusArea ? `${keyFocusArea}:` : ''} </span>
								<span className={classes.focusArea}> {coachingMsg} </span>
							</div>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} style={{ paddingLeft: (small ? 0 : null), paddingRight: (small ? 0 : null) }}>
					<Paper className={classes.dashboardCard}>
						<h3 className={classes.chartHeader}>
							<Typography className={classes.dataChartTitle}>Data and Statistics</Typography>
						</h3>
						<Grid container justify={(medium ? 'center' : 'flex-end')} style={{ paddingBottom: 50 }}>
							<Grid item xs={12} md={3} >
								<FormControl className={classes.chartSelect} style={{ paddingBottom: 10 }}>
									<InputLabel className={classes.selectLabels}>Statistics</InputLabel>
									<Select
										style={{
											height: '2em',
											minWidth: '10em'
										}}
										fullWidth
										value={chartAttribute}
										defaultValue=''
										onChange={(e) => {
											setChartAttribute(e.target.value);
										}}
										required
										MenuProps={MenuProps}
										className={classes.selectedValue}
									>
										{attributeLabels.map((attrib, i) => {
											return (<MenuItem value={attrib[0]} className={classes.menuItems} key={`attribselect-${i}`}>{attrib[1]}</MenuItem>)
										})}
									</Select>
								</FormControl>
							</Grid>
							<form onSubmit={(e) => handleGraphSubmit(e)}>
								<Grid item xs={12} md={1} style={{ }}>
									<FormControl className={classes.chartSelect}>
										<InputLabel id="week-label" className={classes.selectLabels}>Start Range</InputLabel>
										<Select
											style={{
												height: '2em',
												// minWidth: '7em'
												minWidth: '10em'
											}}
											fullWidth
											labelId="week-label"
											value={startDate}
											onChange={(e) => onChangeStartDate(e.target.value)}
											className={classes.selectedValue}
											required
											MenuProps={MenuProps}
										>
											{
												startData.map((x, i) => {
													return (<MenuItem value={x} className={classes.menuItems} key={`attribselect-${i}`}>{x}</MenuItem>)
												})
											}
										</Select>
									</FormControl>
									<FormControl className={classes.chartSelect}>
										<InputLabel id="week-label" className={classes.selectLabels}>End Range</InputLabel>
										<Select
											style={{
												height: '2em',
												// minWidth: '7em'
												minWidth: '10em'
											}}
											fullWidth
											labelId="week-label"
											value={endDate}
											onChange={(e) => onChangeEndDate(e.target.value)}
											className={classes.selectedValue}
											required
											MenuProps={MenuProps}
										>
											{
												endData.map((x, i) => {
													return (<MenuItem value={x} className={classes.menuItems} key={`attribselect-${i}`}>{x}</MenuItem>)
												})
											}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} md={6}>
									<Button variant="contained" type="submit" fullWidth={(small ? false : true)} style={{ backgroundColor: '#797198', color: 'white', marginTop: 10, fontSize: 14, letterSpacing: 0.14, fontFamily: 'proxima_novabold', minWidth: (small ? 300 : null) }}>Submit</Button>
								</Grid>
							</form>
						</Grid>
						<ResponsiveContainer width="93%" height={300}>
							<LineChart height={300} data={chart}>
								<XAxis dataKey="time" tickFormatter={formatDateTicks} />
								<YAxis />
								<CartesianGrid stroke="#eee" strokeDasharray="5 5" />
								<Line type="monotone" dataKey="value" stroke="#8884d8" />
							</LineChart>
						</ResponsiveContainer>
					</Paper>
				</Grid>
			</Grid>}
		</div>
	)
}

export default DriverDashboard
