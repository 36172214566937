import { useState, useRef, useContext, useEffect } from "react";
import { Button, Hidden, TextField, Grid, InputAdornment, useMediaQuery, useTheme, Typography } from '@material-ui/core';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import LoadingCircle from "../components/LoadingCircle";
import '../fonts/styles.css';
import { fetchWithAuth } from "../utils/utils";
import logo from '../plumtree.png';
import useStyles from "./styles";
import HeaderContext from "../utils/HeaderContext";

const ScorecardUpload = (props) => {
	const [file, setFile] = useState('')
	const [loading, setLoading] = useState('false');
	const [msg, setMsg] = useState('');
	const inputFile = useRef(null);
	const classes = useStyles();
	const matches = useMediaQuery(useTheme().breakpoints.down('sm'));
	const { header, setHeader } = useContext(HeaderContext);

	useEffect(()=>{
		setHeader("Upload");
	},[])
	
	const onButtonClick = () => {
		// `current` points to the mounted file input element
		inputFile.current.click();
	};

	const handleSubmit = () => {
		setLoading('true');
		postFile();
	}

	const intervalCall = (trackerId) => {
		let retry = 0;
		let request = async () => fetchWithAuth(`${props.api}/api/status`).then((resp) => resp.json());

		const interval = async () => {
			let intervalCall = await request();
			const serverTracker = intervalCall.find(x => x.id === trackerId);

			if (!serverTracker) {
				setLoading("failed");
				setMsg("Unable to determine upload status. Upload may or may not have succeeded.")
				return;
			}
			if (retry === 50) {
				setLoading("failed");
				setMsg("Upload Timed Out")
				return;
			} else if (serverTracker.succeeded === true) {
				setLoading("complete");
				return;
			} else if (serverTracker.failed === true) {
				setLoading("failed");
				setMsg("Scorecard format not supported.");
				return;
			}
			retry++;
			setTimeout(interval, 6000);
		};

		interval();
	}

	const apiEndpoint = `${props.api}/upload`;

	const postFile = () => {
		const s3ReqUrl = new URL(apiEndpoint)
		const fileName = file.name
		s3ReqUrl.searchParams.append("filename", fileName)
		// get presigned URL 
		fetchWithAuth(s3ReqUrl)
			.then(resp => {
				if (resp.status === 401) {
					window.location.pathname = '/';
					return;
				}
				return resp.json();
			})
			.then((resp) => {
				intervalCall(resp.job_id);
				return fetch(resp.url, {
					method: "PUT",
					body: file
				})
			})
			.then((result) => {
				console.log('Upload:', result);
			})
			.catch((error) => {
				console.log('Error:', error);
			})
	}

	return (
		<Grid container spacing={2}>
			<Hidden smDown>
				<Grid item xs={12}>
					<img src={logo} alt='plumtree-logo' className='logo' />
				</Grid>
			</Hidden>
			<Grid item xs={12}>
				<TextField
					onClick={onButtonClick}
					label={file?.name || 'Select File'}
					fullWidth={matches ? true : false}
					style={{ minWidth: (matches ? 0 : 500) }}
					disabled
					className={(file === '' ? classes.fileRoot : classes.afterRoot)}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<InsertDriveFileOutlinedIcon />
							</InputAdornment>
						)
					}}
				/>
				<input type='file' id='file' ref={inputFile} style={{ display: 'none' }} onChange={(e) => { setFile(e.target.files.length === 0 ? file : e.target.files[0]) }} />
			</Grid>
			<Grid container justify='center'>
				<Button
					className={classes.button}
					variant='contained'
					disableElevation
					fullWidth={matches ? true : false}
					disabled={file === '' ? true : false}
					onClick={handleSubmit}
				>
					Submit
				</Button>
			</Grid>
			{loading === 'true' ?
				<Grid container justify='center' style={{ marginTop: '10em' }}>
					<LoadingCircle />
				</Grid>
				: loading === 'complete' ?
					<Grid container justify='center' style={{ marginTop: '10em' }}>
						<Typography className={classes.successText}>Upload Complete</Typography>
					</Grid>
					: loading === 'failed' ?
						<Grid container justify='center' style={{ marginTop: '10em' }}>
							<Typography className={classes.failedText}>Upload Failed</Typography>
							<Grid item xs={12}  >
								<Typography className={classes.failedTitle}>Error: </Typography>
								<Typography className={classes.failedMsg}>{msg}</Typography>
							</Grid>
						</Grid>
						: null}
		</Grid>
	)
}

export default ScorecardUpload
