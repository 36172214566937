import useStyles from './styles'

const Sidebar = () => {
	const classes = useStyles();
	const pages = {
		"Upload": '/upload',
		"Coaching": '/driver',
		"Team": "/team",
		"Fleet": "/fleet"
	}

	return (

		<div>
			<div className={classes.sidebarHeader}> Scorecard </div>
			<ul className={classes.uploadList}>
				{Object.entries(pages).map(([label, path]) => (
					<li
						className={
							path === window.location.pathname ? classes.sidebarSelected : classes.sidebarInactive
						}
						key={`${label}-link`}
						onClick={(e) => {
							window.location.pathname = path
						}}
					>
						{label}
					</li>
				))}
			</ul>
		</div>
	)
}

export default Sidebar
