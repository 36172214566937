import { makeStyles } from "@material-ui/core";
import '../fonts/styles.css';

const useStyles = makeStyles(()=> ({
    //ListCard
	tableCellTitle: {
		fontSize: 16,
		color: '#242424',
		fontFamily: 'proxima_novasemibold'
	},
	tableCellValue:{
		fontSize: 16,
		color: '#242424',
		fontFamily: 'proxima_novaregular'
	},
    //OverallScore
    root: {
        width: '100%',
        height: '100%',
      },
      smallGrid: {
        '&.MuiGrid-container': {
          justifyContent: 'space-evenly',
          marginBottom: -50,
          marginTop: 50
        }
      },
      largeGrid: {
        '&.MuiGrid-container': {
          justifyContent: 'space-evenly',
          marginBottom: -50,
          marginTop: 50
        }
    },

}))

export default useStyles;