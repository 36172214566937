import { useEffect } from 'react';
import '../fonts/styles.css';

const Auth = (props) => {
	const params = new window.URL(window.location.toString()).searchParams

	const redirect = (url) => {
		if (url) window.location.href = url
	}

	useEffect(() => {
		if (params.get('code')) {
			login(params.get('code'))
		} else {
			fetch(`${props.api}/info`)
				.then(resp => resp.json())
				.then(data => {
					if (data.mock_login) {
						console.log('setting stuff');
						localStorage.setItem('token', 'mock')
						redirect(`${window.location.href}upload`)
					} else {
						redirect(data.login_url)
					}
				})
				.catch(e => { console.error('Error fetching config') })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params])

	const login = (code) => {
		if (!code) { return null }
		const codeUrl = new URL(`${props.api}/login`, window.location.origin)
		codeUrl.searchParams.append('code', code)
		codeUrl.searchParams.append('grant_type', 'authorization_code')

		fetch(codeUrl)
			.then(resp => resp.json())
			.then(data => {
				window.localStorage.setItem("token", data.access_token)
				redirect(`${window.location.origin}/upload`)
			})
			.catch(e => {
				console.error(e)
				alert('authentication error')
			})
	}

	return (
		<h2 style={{fontFamily: "proxima_novabold"}}>{params.get('code') ? 'Logging you in...' : 'Redirecting to login'} </h2>
	)
}

export default Auth;
