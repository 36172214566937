import React from 'react';
import {CircularProgress, Box, Typography} from '@material-ui/core/';
import '../fonts/styles.css';
import useStyles from './styles';

function Loading() {
    const classes = useStyles();
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress size={'13em'} className={classes.circle} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography component="div" className={classes.loadingText}>Loading</Typography>
            </Box>
        </Box>
    );
}

export default function LoadingCircle() {
    return <Loading />;
}