import React from 'react';
import { Card, CardContent, Grid, useMediaQuery, useTheme } from '@material-ui/core/';
import '../fonts/styles.css';
import ScoreGraph from '../components/OverallScoreGraph';
import useStyles from './styles';

export default function OverallScore({ score, safety, quality }) {
  const classes = useStyles();
  const matches = useMediaQuery(useTheme().breakpoints.down('sm'));
  const criteria = {
    0: { "grade": 'Loading...', "color": "black" },
    1: { "grade": 'Poor', "color": "#C00000" },
    2: { "grade": 'Fair', "color": "#ED7D31" },
    3: { "grade": 'Great', "color": "#00AF50" },
    4: { "grade": 'Fantastic', "color": "#00aeff" },
    5: { "grade": 'Fantastic+', "color": "#0095FF" }
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <h3
          style={{
            display: 'flex',
            color: '#242424',
            fontSize: 24,
            letterSpacing: 0.24
          }}
        >
          <span style={{ fontFamily: 'proxima_novabold', fontSize: 24, letterSpacing: 0.24, color: '#242424' }}> Overall Score: </span>
          <span style={{ paddingLeft: 16, color: (score === '-' ? "black" : criteria[String(score / 2).split("")[0]].color), fontFamily: 'proxima_novabold' }}> {(score === '-' ? "No Data" :  criteria[String(score / 2).split("")[0]].grade)} </span>
        </h3>
        <Grid container style={{ overflow: 'hidden' }} direction={(matches ? null : 'column')} alignItems={(matches ? null : 'center')} justify={(matches ? null : 'center')} className={(matches ? classes.smallGrid : classes.largeGrid)}>
          <Grid item xs={12} sm={6} md={12} lg={12} >
            <ScoreGraph title={"Safety"} grading={(safety === '-' ? "No Data" : safety)} color={(safety === '-' ? "black" : criteria[String(safety / 2).split("")[0]].color)} />
          </Grid>
          <Grid item xs={12} sm={6} md={12} lg={12}>
            <ScoreGraph title={"Quality"} grading={(quality === '-' ? "No Data" : quality)} color={(quality === '-' ? "black" : criteria[String(quality / 2).split("")[0]].color)} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
