import React from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import '../fonts/styles.css';
import useStyles from './styles';

function createData(name, data) {
  return { name, data };
}

const rows = [
  createData('Coming Soon', 'Coming Soon'),
  createData('Coming Soon', 'Coming Soon'),
  createData('Coming Soon', 'Coming Soon'),
  createData('Coming Soon', 'Coming Soon'),
  createData('Coming Soon', 'Coming Soon'),
  createData('Coming Soon', 'Coming Soon'),
];

export default function ReliabilityTable() {
  const classes = useStyles();

  return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCellTitle}>Coming Soon</TableCell>
            <TableCell align="right" className={classes.tableCellTitle}>Coming Soon</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row,i) => (
            <TableRow key={`${row.name}-${i}`}>
              <TableCell component="th" scope="row" className={classes.tableCellTitle}>
                {row.name}
              </TableCell>
              <TableCell align="right" className={classes.tableCellValue} >{row.data}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
  );
}