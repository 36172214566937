import BottomAppBar from './components/BottomBar'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import './App.css'
import ScorecardUpload from './Pages/Upload'
import DriverDashboard from './Pages/DriverDashboard'
import Sidebar from './components/Sidebar'
import Topbar from './components/Topbar'
import { Hidden } from '@material-ui/core'
import HeaderContext from './utils/HeaderContext'
import { useState } from 'react'

import Auth from './Pages/Auth'

import {
	BrowserRouter,
	Route,
	Switch,
} from 'react-router-dom';
import ComingSoon from './Pages/ComingSoon'

const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#4791db"
		}
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 370, //was 600
			md: 960,
			lg: 1280,
			xl: 1920,
		},
	},
})

function App() {
	const apiEndpoint = "https://vhts16ixi4.execute-api.us-east-1.amazonaws.com/Prod"
	// const apiEndpoint = "http://localhost:5000"

	const [ header, setHeader ] = useState("");

	// redirect to https
	if (window.location.protocol !== 'https:') {
		window.location.protocol = 'https:'
	}

	return (
		<BrowserRouter>
			<HeaderContext.Provider value={{ header, setHeader }}>
				<ThemeProvider theme={theme}>
					<div className="App">
						<div className="sidebar">
							<Sidebar />
						</div>
						<Hidden mdUp>
							<BottomAppBar />
						</Hidden>
						<div className='content'>
							<Topbar />
							<Switch>
								<Route exact path='/'>
									<Auth
										api={apiEndpoint}
									/>
								</Route>

								<Route exact path='/upload'>
									<header className="App-header">
										<ScorecardUpload
											api={apiEndpoint}
										/>
									</header>
								</Route>

								<Route exact path='/driver'>
									<header className="dashboard-content">
										<DriverDashboard
											api={apiEndpoint}
										/>
									</header>
								</Route>
								<Route exact path='/fleet'>
									<header className="dashboard-content">
										<ComingSoon
											api={apiEndpoint}
										/>
									</header>
								</Route>
								<Route exact path='/team'>
									<header className="dashboard-content">
										<ComingSoon
											api={apiEndpoint}
										/>
									</header>
								</Route>
							</Switch>
						</div>
					</div>
				</ThemeProvider>
			</HeaderContext.Provider>
		</BrowserRouter>
	);
}

export default App;
