import { Button, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import logo from '../plumtree.png';
import '../fonts/styles.css';
import useStyles from './styles';
import { useContext } from 'react';
import HeaderContext from '../utils/HeaderContext';

const logOut = () =>{
	window.localStorage.removeItem("token");
	window.location.pathname = '/';
}

const Topbar = () => {
	const classes = useStyles();
	const { header } = useContext(HeaderContext);
	const small = useMediaQuery(useTheme().breakpoints.down('sm'));

	return (
		<div className={classes.topbar}>
			<div>
				<img src={logo} alt='bestlandt-logo' style={{ maxHeight: '3em' }} />
			</div>
			<div>
				<Typography component='h1' className={classes.topbarText} >{ small ? null : header}</Typography>
			</div>
			<div>
				<Button style={{color: 'red', fontSize: '1em', fontFamily: 'proxima_novaregular', fontWeight: 600}} onClick={logOut}>Log Out</Button>
			</div>
		</div>
	)
}

export default Topbar;
