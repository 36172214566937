import ListCard from "./ListCard"
import { Card, CardContent } from "@material-ui/core"
import '../fonts/styles.css';

const StatListCard = ({ headerText, tableHeaders, showRows, data, labelMap, score }) => {
	const thead = [
		{ value: tableHeaders[0], align: "left" },
		{ value: tableHeaders[1], align: "right" },
	]

	const criteria = {
		0: { "grade": 'Loading...', "color": "black" },
		1: { "grade": 'Poor', "color": "#C00000" },
		2: { "grade": 'Fair', "color": "#ED7D31" },
		3: { "grade": 'Great', "color": "#00AF50" },
		4: { "grade": 'Fantastic', "color": "#00aeff" },
		5: { "grade": 'Fantastic+', "color": "#0095FF" }
	}

	// generate row data 
	let tableData = []
	if (data && labelMap) {
		tableData = showRows.map((metric) => {

			const label = labelMap?.[metric] || "-"
			const value = data?.[metric] || "-"
			return [label, value]
		})
	}

	return (
		<Card style={{ height: '100%' }}>
			<CardContent>
				<h3
					style={{
						display: 'flex'
					}}
				>
					<span style={{ fontSize: 24, letterSpacing: 0.24, color: '#242424', fontFamily: 'proxima_novabold' }} > {headerText} </span>
					<span style={{ paddingLeft: 16, color: ( score === '-' ? "black" : criteria[String(score / 2).split("")[0]].color), fontSize: 24, letterSpacing: 0.24, fontFamily: 'proxima_novabold' }}> {score === '-' ? "No Data" : criteria[String(score / 2).split("")[0]].grade} </span>
				</h3>
				<ListCard
					columnLabels={thead}
					rows={tableData}
				/>
			</CardContent>
		</Card>

	)
}

export default StatListCard
