const decodeToken = token => token.split('.').map(tokenPart => Buffer.from(tokenPart, 'base64').toString())

const checkExp = token => {
	const [_, body, __] = decodeToken(token)
	return new Date(body?.exp) < Date.now() 
}

const fetchWithAuth = (url, options={}) => {
	const {method, body, headers, credentials} = options

	//if token is expired, redirect to login page
	const token = window.localStorage.getItem("token")
	if(checkExp(token)){
		// window.location.href = `${window.location.origin}/`
		window.location.pathname = '/';
	}
	const headersWithAuth = {
		...headers,
		'Authorization': token || ''
	}
	console.log(headersWithAuth);
	return fetch(url, {
		method,
		credentials,
		body,
		headers: headersWithAuth,
	})
}

export {
	decodeToken,
	checkExp,
	fetchWithAuth
}
