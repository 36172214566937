import { Card, CardContent } from "@material-ui/core"
import ReliabilityTable from "../components/ReliabilityGraph";
import '../fonts/styles.css';

const ReliabilityCard = ({ headerText }) => {

	return (
		<Card style={{ height: '100%' }}>
			<CardContent>
				<h3
					style={{
						display: 'flex'
					}}
				>
					<span style={{ fontSize: 24, letterSpacing: 0.24, color: '#242424', fontFamily: 'proxima_novabold' }} > {`${headerText}:`} </span>
					<span style={{ paddingLeft: 16, color: '#0095FF', fontSize: 24, letterSpacing: 0.24, fontFamily: 'proxima_novabold' }}> Coming Soon! </span>
				</h3>
				<ReliabilityTable />
			</CardContent>
		</Card>

	)
}

export default ReliabilityCard;