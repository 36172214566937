import { makeStyles } from "@material-ui/core";
import '../fonts/styles.css';

const useStyles = makeStyles((theme) => ({
    //DriverDashboard
    root: {
        '&.MuiGrid-item': {
            paddingLeft: 0
        }
    },
    selectLabels: {
        '&.MuiInputLabel-root, .Mui-focused': {
            color: '#79719880',
            opacity: .75,
            fontSize: 16,
            fontFamily: 'proxima_novaregular'
        }
    },
    selectedValue: {
        color: '#797198',
        fontSize: 16,
        fontFamily: 'proxima_novaregular'
    },
    dataChartTitle: {
        fontSize: 24,
        fontWeight: 600,
        letterSpacing: 0.24,
        color: '#242424',
        fontFamily: 'proxima_novabold'
    },
    menuItems: {
        fontSize: 15,
        color: '#102F49',
        fontFamily: 'proxima_novaregular'
    },
    dashboardSelect: {
        minWidth: '5em',
        marginRight: '2em',
        width: '100%'
    },
    chartHeader: {
        display: 'flex',
        paddingLeft: '2em',
        paddingRight: '2em',
        marginTop: '.5em',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: -5
    },
    chartSelect: {
        alignSelf: 'flex-end'
    },
    dashboardCard: {
        padding: '1em',
        paddingBottom: 100
    },
    backdrop:{
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    focusArea: {
        fontFamily: 'proxima_novaregular',
        fontSize: 16,
        color: '#242424'
    },
	pageHeader:{
		fontFamily: 'proxima_novabold',
        fontSize: 20,
        color: '#797198',
		paddingTop: 10,
		paddingBottom: 10
	},
    //Upload
    margin: {
		margin: theme.spacing(1),
	},
	button: {
		'&.Mui-disabled': {
			marginTop: 10,
			color: 'white',
			fontFamily: 'proxima_novabold',
			fontSize: 14,
			letterSpacing: .14,
			height: 50,
			backgroundColor: '#797198',
			opacity: .5
		},
		'&.MuiButton-root': {
			marginTop: 10,
			color: 'white',
			fontFamily: 'proxima_novabold',
			fontSize: 14,
			letterSpacing: .14,
			height: 50,
			backgroundColor: '#797198',
		}
	},
	fileRoot: {
		"& .MuiFormLabel-root": {
			color: '#79719880',
			fontSize: 16,
			opacity: .75,
			fontFamily: 'proxima_novaregular',
		}
	},
	successText: {
		color: '#797198',
		fontSize: 24,
		letterSpacing: 0.24,
		fontWeight: 600,
		fontFamily: 'proxima_novabold'
	},
	afterRoot: {
		"& .MuiFormLabel-root": {
			color: '#79719880',
			fontSize: 16,
			fontFamily: 'proxima_novaregular',
			fontWeight: 600
		}
	},
	failedText: {
		color: 'red',
		fontSize: 24,
		letterSpacing: 0.24,
		fontWeight: 600,
		fontFamily: 'proxima_novabold'
	},
	failedTitle: {
		fontSize: 24,
		letterSpacing: 0.24,
		fontWeight: 600,
		fontFamily: 'proxima_novabold',
		display: 'inline-block'
	},
	failedMsg: {
		fontSize: 20,
		letterSpacing: 0.24,
		fontFamily: 'proxima_novaregular',
		display: 'inline-block',
		paddingLeft: 10
	}

}))

export default useStyles;