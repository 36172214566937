import { makeStyles } from "@material-ui/core";
import '../fonts/styles.css';

const useStyles = makeStyles(() => ({
    //Sidebar
    sidebarSelected: {
        fontSize: '1.1em',
        paddingTop: '0.5em',
        paddingBottom: '0.5em',
        paddingLeft: '2em',
        userSelect: 'none',
        color: 'white'
    },
    sidebarInactive: {
        fontSize: '1.1em',
        paddingTop: '0.5em',
        paddingBottom: '0.5em',
        paddingLeft: '2em',
        userSelect: 'none',
        color: '#9899ac'
    },
    sidebarHeader: {
        fontSize: '1.1em',
        fontWeight: 600,
        marginTop: '1em',
        textAlign: 'left',
        marginLeft: '1.3em',
        color: '#9899ac'
    },
    uploadList: {
        textAlign: 'left',
        listStyleType: 'none',
        paddingLeft: '0em'
    },
    //Topbar
    topbar: {
        display: 'flex',
        paddingLeft: '1em',
        paddingRight: '.5em',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: '0px 3px 6px #00000029'
    },
    topbarText:{
        fontFamily: 'proxima_novabold',
        fontSize: 20,
        color: '#797198'
    },
    //BottomBar
    appBar: {
        top: 'auto',
        bottom: 0,
        color: 'white'
    },
    toolBar: {
        justifyContent: 'space-around',
        backgroundColor: '#797198',
        '&.MuiToolbar-regular': {
            maxHeight: 56
        }
    },
    text: {
        fontSize: 12,
        marginTop: -10
    },
    //LoadingCircle
    circle: {
        '&.MuiCircularProgress-root': {
            color: '#797198'
        }
    },
    loadingText:{
        color: '#797198',
        fontSize: 24,
        letterSpacing: 0.24,
        fontWeight: 600,
        fontFamily: 'proxima_novabold'
    },
    //ReliabilityGraph
    tableCellTitle: {
		fontSize: 16,
		color: '#242424',
		fontFamily: 'proxima_novasemibold'
	},
	tableCellValue:{
		fontSize: 16,
		color: '#242424',
		fontFamily: 'proxima_novaregular'
	},
    
}))

export default useStyles;