import React from 'react';
import { useState } from 'react';
import { AppBar, CssBaseline, Toolbar, IconButton, Typography } from '@material-ui/core/';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import SpeedOutlinedIcon from '@material-ui/icons/SpeedOutlined';
import useStyles from './styles';


export default function BottomAppBar() {
    const [menu, setMenu] = useState(window.location.pathname);
    const classes = useStyles();

    return (
        <>
            <CssBaseline />
            <AppBar position="fixed" color="primary" className={classes.appBar}>
                <Toolbar className={classes.toolBar}>
                    <IconButton color="inherit" onClick={() => {
                        setMenu('/upload')
                        window.location.pathname = '/upload';
                    }}>
                        <div style={{ opacity: (menu === '/upload' ? 1 : .4) }}>
                            <BackupOutlinedIcon />
                            <Typography className={classes.text}>Upload</Typography>
                        </div>
                    </IconButton>
                    <IconButton color="inherit" onClick={() => {
                        setMenu('/driver')
                        window.location.pathname = '/driver';
                    }}>
                        <div style={{ opacity: (menu === '/driver' ? 1 : .4) }}>
                            <SpeedOutlinedIcon />
                            <Typography className={classes.text}>Coaching</Typography>
                        </div>
                    </IconButton>
                </Toolbar>
            </AppBar>
        </>
    );
}
