import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import '../fonts/styles.css';
import useStyles from './styles';

/*
List Table card.
Props:
columnLabels: object
	{ value: <column label>, align: <column value alignment, left or right> }

rows: List of values to display in column order
score: 
*/

const ListCard = (props) => {
	const classes = useStyles();
	const { columnLabels, rows } = props

	return (
		<div>
			<Table>
				<TableHead>
					<TableRow >
						<TableCell className={classes.tableCellTitle} align={columnLabels[0].align}> {columnLabels[0].value} </TableCell>
						<TableCell className={classes.tableCellTitle} align={columnLabels[1].align}>{columnLabels[1].value}</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{
						rows.map(row => (
							<TableRow>
								{
									row.map((value, i) => (
										<TableCell key={`${value}-${i}`} className={isNaN(value.split("")[0]) ? classes.tableCellTitle : classes.tableCellValue} align={columnLabels[i].align}> {value} </TableCell>
									))
								}
							</TableRow>
						))
					}
				</TableBody>
			</Table>
		</div>

	)
}

export default ListCard
